// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loginTitle {

}


#loginEmail {
	
}

#loginPassword {
	
}

#loginPasswordContainer {
    position: relative;
}

#loginForgotten {
	position: absolute;
  right: var(--inputPadding);
  top: 50%;
  transform: translateY(-50%);
}

#loginButton {
	background-color: var(--main-hg-color);
}

.loginLink {
	text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.container__loginpage:not(.container__information__register__product) #headerLogo {
    margin-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;;AAEA;;;AAGA;;AAEA;;AAEA;;AAEA;;AAEA;IACI,kBAAkB;AACtB;;AAEA;CACC,kBAAkB;EACjB,0BAA0B;EAC1B,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;CACC,sCAAsC;AACvC;;AAEA;CACC,kBAAkB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["#loginTitle {\n\n}\n\n\n#loginEmail {\n\t\n}\n\n#loginPassword {\n\t\n}\n\n#loginPasswordContainer {\n    position: relative;\n}\n\n#loginForgotten {\n\tposition: absolute;\n  right: var(--inputPadding);\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n#loginButton {\n\tbackground-color: var(--main-hg-color);\n}\n\n.loginLink {\n\ttext-align: center;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.container__loginpage:not(.container__information__register__product) #headerLogo {\n    margin-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
