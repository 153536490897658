import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiProduct from "../Api/ApiProduct";

export default class ProductService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiProduct();
        this.#helper = new HelperService();
    }


    async destroyed(key, form) {
        const result = await this.#api.destroy(key, form);
        return result;
    }

    async received(key) {
        var shop = sessionStorage['firstname']
        if (shop){
            const result = await this.#api.received(shop,key);
            return result;
        }
    }

    async lost(key, form) {
        const result = await this.#api.lost(key, form);
        return result;
    }

    async send(key,shop) {
        const result = await this.#api.send(key,shop);
        return result;
    }

    async stolen(key, form) {
        const result = await this.#api.stolen(key, form);
        return result;
    }

    async cancel(key) {
        const result = await this.#api.cancel(key);
        return result;
    }

    async sell(product, address) {
        const result = await this.#api.sell(product, address);
        return result;
    }0

    async read(gtin, serial){
        const result = await this.#api.read(gtin, serial);
        return result;
    }

    async history(product){
        const result = await this.#api.history(product);
        return result;
    }

    async listFromModel(key){
        const result = await this.#api.listFromModel(key);
        return result;
    }

    async listState(state){
        const result = await this.#api.listState(state);
        return result;
    }

    async getToken(seal,sku,product){
        const result = await this.#api.getToken(seal,sku,product);
        return result;
    }

    async checkSignature(signature){
        const result = await this.#api.checkSignature(signature);
        return result;
    }

    async validate(product) {
        const result = await this.#api.validate(product);
        return result;
    }

    async linkToModel(product, model) {
        const result = await this.#api.linkToModel(product, model);
        return result;
    }
}
