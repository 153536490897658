// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
    border : none;
    border-radius: 0;
}

.message.info {
    background-color: var(--main-hg-color);
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/styles/appMessage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,sCAAsC;IACtC,WAAW;AACf","sourcesContent":[".message {\n    border : none;\n    border-radius: 0;\n}\n\n.message.info {\n    background-color: var(--main-hg-color);\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
