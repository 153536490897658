import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState,} from "react";
import { HeaderLogo } from "../components/headerLogoComponent";
import '../../styles/subscribe.css';
import {motion as m} from "framer-motion"
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import Select from 'react-select';
import ClientService from "../Services/Bll/ClientService";
import SelectLanguageComponent from "../components/SelectLanguageComponent";

export default function SubscribePage() {
    const initialForm = {
        name: '',
        lastname: '',
        title: 'Mme/mr',
        age: -1,
        phones: {},
        mails: {},
        addresses: {}

    }
    const settingService = new SettingService()
    const clientService = new ClientService()
    const navigate = useNavigate();

    const [form, setForm] = useState(initialForm);
    const [mails, setMails] = useState({});
    const [phones, setPhones] = useState({});
    const [addresses, setAddresses] = useState({});
    const [optinTypes, setOptinTypes] = useState([]);
    const [confirmation, setConfirmation] = useState('');
    const [settings, setSettings] = useState(null);
    const [errors, setErrors] = useState({});
    const [regexPw, setRegexPw] = useState('');
    const [checkboxes, setCheckboxes] = useState({
        cgv: false,
        rgpd: false
    });
    const [successCreate, setSuccessCreate] = useState(false);

    useEffect(() => {
        if (sessionStorage.length === 0){
            helperService.setLocalStorage();
        }

        settingService.getFromGroup('optInTypes').then(function (result) {
            setSettings(Object.keys(result.getData()))
        })

        settingService.getFromGroup('app').then(function (result) {
            let setting = result.getData()
            let regex = new RegExp(setting.passwordValidation.text)
            setRegexPw(regex)
        })


    }, [])
    const validate = (data) => {
        const newErrors = {};

        if(!data.password || data.password !== confirmation) {
            newErrors.password = CacheExtension.getDataFromCache("PasswordDoesntMatch")
        }
        if (!regexPw.test(data.password)) {
            newErrors.passwordMatch = CacheExtension.getDataFromCache("PasswordDoesntMatchPattern");
        }

        if(data.mails) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const errors = [];
            Object.keys(data.mails).forEach((email, index) => {
                if (!emailRegex.test(email)) {
                    errors[index] = CacheExtension.getDataFromCache("InvalidEmail");
                }
            });
            if(errors.length){
                newErrors.mails = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidEmail")]
            newErrors.mails = error
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const submitSubscribe = async (e) => {
        e.preventDefault();

        let optin = {};
        for (const i of optinTypes) {
            optin[i.value] = 1;
        }

        const updatedForm = {
            ...form,
            hasAcceptedLegalMentions: true,
            mails,
            phones: phones ? phones : {},
            addresses: addresses ? addresses : {},
            optIns : optin,
        };
        if(validate(updatedForm)) {
            let result = await clientService.subscribe(updatedForm)
            if (result.getCode() === 0) {
                setSuccessCreate(true)
            } else if(result.getCode() === -1176) {
                navigate('/resend')
            }
        }
    };

    const handleFormChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    const handleChangeEmail = event => {
        if(event.target.value.trim() !== "") {
            setMails({
                [event.target.value] : 1
            });
        } else {
            setMails({});
        }
    }

    const handleChangePhone = event => {
        if(event.target.value.trim() !== "") {
            setPhones({
                [event.target.value] : 1
            });
        } else {
            setPhones({});
        }
    }

    const handleChangeAddress = event => {
        if(event.target.value.trim() !== "") {
            setAddresses({
                [event.target.value] : 1
            });
        } else {
            setAddresses({});
        }
    }


    const onChangeSelect = (
        newValue,
        actionMeta
    ) => {
        switch (actionMeta.action) {
            case 'remove-value':
                newValue = optinTypes.filter(item => item !== actionMeta.removedValue)
            case 'clear':
                newValue = []
                break;
        }
        setOptinTypes(newValue);
    };

    const handleCheckboxChange = (event) => {
        setCheckboxes({
            ...checkboxes,
            [event.target.name]: event.target.checked
        });
    };

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                <div className={"w-100 d-flex justify-content-end containerLanguage"}>
                    <div className={"selectLanguage"}>
                        <SelectLanguageComponent/>
                    </div>
                </div>
                <div className={'titleHead container__loginpage'} id="header">
                    <HeaderLogo />
                    {/*<div id="connectPicture"></div>*/}
                </div>
                <div id="content">
                    {!successCreate ?
                        <>
                            <div id="subscribeIntro" className="textPR">{CacheExtension.getDataFromCache("subscribe.intro")}</div>
                            <form className={'formNew'} onSubmit={(e) => submitSubscribe(e)}>
                                <input type="text" placeholder={CacheExtension.getDataFromCache("Firstname")} name="name" id="subscribeFirstname" onChange={handleFormChange}/>
                                {errors.name && <div className="showError">{errors.name}</div>}
                                <input type="text" placeholder={CacheExtension.getDataFromCache("Lastname")} name="lastname" id="subscribeLastname" onChange={handleFormChange}/>
                                {errors.lastname && <div className="showError">{errors.lastname}</div>}
                                <input type="email" placeholder={"* " + CacheExtension.getDataFromCache("email")} name="email" id="subscribeEmail" onChange={handleChangeEmail}/>
                                {errors.mails && <div className="showError">{errors.mails}</div>}
                                <input className={errors.passwordMatch && "inputError"} type="password" placeholder={"* " + CacheExtension.getDataFromCache("Password")} name="password" id="subscribePassword" onChange={handleFormChange}/>
                                <input className={errors.passwordMatch && "inputError"} type="password" placeholder={"* " + CacheExtension.getDataFromCache("ConfirmPassword")} name="confirmation" id="subscribeConfirmation" onChange={(e)=> setConfirmation(e.target.value)}/>
                                <div>{CacheExtension.getDataFromCache("PasswordDoesntMatchPattern")}</div>
                                {errors.password && <div className="showError">{errors.password}</div>}
                                <input type="tel" placeholder={CacheExtension.getDataFromCache("phone")} name="phone" id="subscribePhone" onChange={handleChangePhone} pattern="[0-9+]*"
                                       onKeyDown={(event) => {
                                           if (!/[0-9+]/.test(event.key) && ![ 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight' ].includes(event.key)) {
                                               event.preventDefault();
                                           }
                                       }}/>
                                {errors.phones && <div className="showError">{errors.phones}</div>}
                                <input type="text" placeholder={CacheExtension.getDataFromCache("address")} name="address" id="address" onChange={handleChangeAddress}/>
                                {errors.addresses && <div className="showError">{errors.addresses}</div>}
                                {/*<input type="number" placeholder={CacheExtension.getDataFromCache("Age")} name="age" id="age" onChange={handleFormChange}/>*/}
                                {/*{errors.age && <div className="showError">{errors.age}</div>}*/}
                                {/*<input type="text" placeholder={CacheExtension.getDataFromCache("PersonTitle")} name="title" id="title" onChange={handleFormChange}/>*/}
                                {/*{errors.title && <div className="showError">{errors.title}</div>}*/}


                                {/*<InputComponent onInputChange={(values) => setMails(values)} placeholder={'email'} type={'email'} errors={errors.mails} mandatory={true}/>*/}
                                {/*<InputComponent onInputChange={(values) => setPhones(values)} placeholder={'phone'} type={'tel'} errors={errors.phones}/>*/}
                                {/*<InputComponent onInputChange={(values) => setAddresses(values)} placeholder={'address'} type={'text'} errors={errors.addresses}/>*/}
                                <Select
                                    isMulti
                                    name="optin"
                                    className={'d-none'}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            neutral20: 'rgb(255, 158, 23)',
                                            neutral30: 'rgb(255, 158, 23)',
                                            primary25: 'rgba(255, 158, 23, 0.5)',
                                            danger: 'rgb(255, 158, 23)',
                                            dangerLight: 'rgba(255, 158, 23, 0.2)',
                                            primary: 'black',
                                        },
                                    })}
                                    placeholder={"notifications.select"}
                                    options={settings && settings.map((key, i) => ({value: key, label: CacheExtension.getDataFromCache(key)}))}
                                    onChange={onChangeSelect}
                                />
                                <span>{CacheExtension.getDataFromCache("required")}</span>
                                <div className="form-check">
                                    <input style={{width:"auto"}} className="form-check-input" type="checkbox" name="newsletter" id="newsletter"/>
                                    <label className="form-check-label" htmlFor="newsletter">
                                        {CacheExtension.getDataFromCache("newsletter")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        style={{width:"auto"}}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="cgv"
                                        id="cgv"
                                        checked={checkboxes.cgv}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="cgv">
                                        * {CacheExtension.getDataFromCache("cgv")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        style={{width:"auto"}}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="rgpd"
                                        id="rgpd"
                                        checked={checkboxes.rgpd}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="rgpd">
                                        * {CacheExtension.getDataFromCache("rgpd")}
                                    </label>
                                </div>
                                <button id="subscribeButton" disabled={!checkboxes.cgv || !checkboxes.rgpd} className="button" type="submit">{CacheExtension.getDataFromCache("subscribe.signon")}</button>
                                <Link className="btnReverse" id="subscribeLogin" to={`/login`}>{CacheExtension.getDataFromCache("subscribe.login")}</Link>
                            </form>
                        </> :
                        <>
                            <form className={'formNew'}>
                                <div className={"mb-5"}>{CacheExtension.getDataFromCache('CheckYourMailMessage')}</div>
                            </form>
                            {/*{!confirmMessage ?*/}
                            {/*    <form className={'formNew'} onSubmit={(e) => submitActivate(e)}>*/}
                            {/*        <div className={"mb-5"}>{CacheExtension.getDataFromCache("subscribe.success")}</div>*/}

                            {/*        /!*<input type="email" autocomplete="off" value={activateForm.email} placeholder={CacheExtension.getDataFromCache("email")} name="email" onChange={handleActivateFormChange}/>*!/*/}
                            {/*        /!*{errors.mails && <div className="showError">{errors.mails}</div>}*!/*/}
                            {/*        <input className="mb-3" type="text" ref={inputTokenRef} placeholder={CacheExtension.getDataFromCache("ConfirmToken")} name="token" onChange={handleActivateFormChange}/>*/}
                            {/*        <div className={"w-100 d-flex justify-content-end mb-3"}>*/}
                            {/*            <button disabled={confirmMessage} type='submit' className={'btn btnBo submit'}>{CacheExtension.getDataFromCache("Validate")}</button>*/}
                            {/*        </div>*/}
                            {/*    </form> :*/}
                            {/*    <form className={'formNew'}>*/}
                            {/*        <div className={"mb-5"}>{CacheExtension.getDataFromCache('ConfirmMessage')}</div>*/}
                            {/*        <Link className="button text-white" to={`/login`}>{CacheExtension.getDataFromCache("login")}</Link>*/}
                            {/*    </form>*/}
                            {/*}*/}
                        </>
                    }

                </div>
            </m.div>
        </>
    );
}


