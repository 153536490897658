import ApiLogin from "../Api/ApiLogin";
import ApiResult from "../../Model/ApiResult";
import HelperService from "./HelperService";
import SettingService from "./SettingService";

export default class LoginService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiLogin();
        this.#helper = new HelperService();
    }

    async confirm(data){
        // cast as ApiResult
        return Object.assign(new ApiResult(), await this.#api.confirm(data));
    }

    async recoverClient(data){
        // cast as ApiResult
        return Object.assign(new ApiResult(), await this.#api.recoverClient(data));
    }

    async changePasswordRecover(data){
        // cast as ApiResult
        return Object.assign(new ApiResult(), await this.#api.changePasswordRecover(data));
    }
    async login(data){
        // cast as ApiResult
        var result = Object.assign(new ApiResult(),await this.#api.login(data))
        if (result.getCode() === 0){
            var r = result.getData().data
            this.#helper.logUser(r['firstname'],
                r['lastname'],
                r['profile'],
                r['defaultLanguage'],
                r['token'],
                r['refresh_token'],
                r['extra']);

            if (localStorage['locale'].toUpperCase() !== r['defaultLanguage']){
                var serviceSettings = new SettingService();
                var resultSettings = await serviceSettings.getFromGroup("labelFront");
                localStorage.setItem("labelFront", JSON.stringify(resultSettings.getData()));
            }

        }
        return result;
    }

    async logout(){
        this.#helper.logoutUser()
    }
}
