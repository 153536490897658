import '../../styles/wallet.css';
import {Dropdown} from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css'
import UsersService from "../../StarterKit/Service/Bll/UsersService";
import HelperService from "../Services/Bll/HelperService";

export default function SelectLanguageComponent() {
    // commented until english is added

    const service = new UsersService()
    const helperService = new HelperService()
    async function select(lang) {
        await HelperService.selectLanguage(lang);
        if(HelperService.isLogged()) {
            await service.me({defaultLanguage: lang})
        }
        window.location.reload()
    }

    return (
        <div >
            <Dropdown
                defaultValue={sessionStorage['language'] ? sessionStorage['language'] : 'FR'}
                fluid
                selection
                onChange={async (e, {value}) => {
                    select(value)
                }}
                options={[{value : 'FR', text: 'FR'}, {value : 'EN', text: 'EN'}]}
            />
        </div>
    );
}








