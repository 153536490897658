import { Link, Navigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../res/back.svg';
import React, { useEffect, useState,} from "react";
import '../../styles/productInvoice.css';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import MediaService from "../Services/Bll/MediaService";
import {ImportComponent} from "../../StarterKit/Components/ImportComponent";
import FileModel from "../../StarterKit/Model/FileModel";
import WalletService from "../Services/Bll/WalletService";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";
import {Document, Page} from "react-pdf";

export default function ProductInvoicePage() {
    let service = new ProductService()
    let mediaService = new MediaService()
    const walletService = new WalletService();

    let { gtin, serial } = useParams()

    const [data, setData] = useState(null);
    const [image, setImage] = useState(null);
    const [history, setHistory] = useState(null);

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    useEffect(() => {
        service.read(gtin, serial).then(function (result) {
            let res = result.getData();
            setData(res);
        })

    },[])

    useEffect(() => {
        if (data && data.invoice && data.invoice != 'N/A' && data.invoice != 'Refused') {
            mediaService.read(data.invoice).then(function (result) {
                let res = result;
                let imageUrl = URL.createObjectURL(res);
                setImage({url: imageUrl, type: res.type});
            });
        }
    }, [data]);

    useEffect(() => {
        if (data && data.key) {
            service.history(data.key).then(function (result) {
                let res = result.getData();
                setHistory(res);
            })
        }
    }, [data]);

    async function  importInvoice(product) {
        $("body").css('cursor','wait');
        var fileModel = new FileModel()
        var file = $('input[type="file"]')[0].files[0];
        if (file) {
            fileModel.title = file.name
            fileModel.file = file
            await walletService.import(fileModel,product);
            window.location.reload()
        }
        $("body").css('cursor','default');
    }

    function onDocumentLoadSuccess(){
        console.log('load')
    }


    return (
        <>
            <AnimatePresence>
                <m.div key="container__animation"
                       className="container__animation"
                       animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                       exit={{ marginLeft : '100vw', x: "inherit" }}
                       transition={{duration: 0.30, ease: "linear"}}>
                    {data &&
                        <>
                                <div className="headerProductToken titleHead" id="header">
                                    <Link className="backLink" id="productInvoiceBack" to={ `/product/${gtin}/${serial}` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                                    <h1 id="productInvoiceTitle">{CacheExtension.getDataFromCache("productInvoice.title")}</h1>
                                    {data.warranty == 0 &&
                                        <span id="productInvoiceNotActivated">{CacheExtension.getDataFromCache("productInvoice.notActivated")}</span>
                                    }
                                </div>

                                <div id={"content"} style={{padding: "20px"}}>
                                    {(!data.invoice || data.invoice == 'reminder') &&
                                        <>
                                            <span id="">{CacheExtension.getDataFromCache("productInvoice.notActivatedText")}</span>
                                            <div className="col-lg-6 col-12">
                                                <form
                                                    className={'col-12'}
                                                    id={'_form'}
                                                    onChange={() => importInvoice(data.key)}
                                                    action="">
                                                    <ImportComponent formName={CacheExtension.getDataFromCache("productInvoice.import")} className={'col-12'} form={false}/>
                                                </form>
                                            </div>
                                        </>
                                    }
                                    {data.invoice && data.warranty != 1 && data.invoice != 'Refused' && data.invoice != 'reminder' &&
                                        <>
                                            <span id="">{CacheExtension.getDataFromCache("productInvoice.verifText")}</span>
                                            <div className="col-lg-6 col-12">
                                                <form
                                                    className={'col-12'}
                                                    id={'_form'}
                                                    onChange={() => importInvoice(data.key)}
                                                    action="">
                                                    <ImportComponent formName={CacheExtension.getDataFromCache("productInvoice.update")} className={'col-12'} form={false}/>
                                                </form>
                                            </div>
                                        </>
                                    }
                                    {data.invoice == 'Refused' &&
                                        <>
                                            <span id="">{CacheExtension.getDataFromCache("productInvoice.refused")}</span>
                                            <div className="col-lg-6 col-12">
                                                <form
                                                    className={'col-12'}
                                                    id={'_form'}
                                                    onChange={() => importInvoice(data.key)}
                                                    action="">
                                                    <ImportComponent formName={CacheExtension.getDataFromCache("productInvoice.import")} className={'col-12'} form={false}/>
                                                </form>
                                            </div>
                                        </>
                                    }
                                    {data.invoice && data.warranty == 1 && data.invoice != 'Refused' && history &&
                                        <div>
                                            {Object.values(history).map((story) =>
                                                    (story.message === "activateWarranty" || story.message === "endWarranty") && (
                                                        <p>
                                                            {CacheExtension.getDataFromCache(story.message)} : {story.param1}
                                                        </p>
                                                    )
                                            )}
                                            {/*<p>{CacheExtension.getDataFromCache("productInvoice.buyDate")} : {data.buyDate}</p>*/}
                                            {/*<p>{CacheExtension.getDataFromCache("productInvoice.endDate")} : {data.warrantyEndDate}</p>*/}
                                            <p>{CacheExtension.getDataFromCache("productInvoice.duration")} : {data.model.isFrench == 1 ? "5" : "3"} {CacheExtension.getDataFromCache("productInvoice.years")}</p>
                                            {image && <div id="productInvoicePicture">
                                                {image.type === "application/pdf" ? (
                                                    <>
                                                        <Document onClick={() => window.open(image.url, "_blank")} file={image.url} onLoadSuccess={onDocumentLoadSuccess}>
                                                            <Page pageNumber={1} width={Math.min(600, window.innerWidth - 20)} renderAnnotationLayer={false} renderTextLayer={false}/>
                                                        </Document>
                                                </>
                                                ) : (
                                                    <img onClick={() => window.open(image.url, "_blank")} src={image.url} alt="invoice"/>
                                                )}
                                            </div>}
                                            {data.invoice == 'N/A' &&
                                                <form
                                                    className={'col-12'}
                                                    id={'_form'}
                                                    onChange={() => importInvoice(data.key)}
                                                    action="">
                                                    <ImportComponent formName={CacheExtension.getDataFromCache("productInvoice.import")} className={'col-12'} form={false}/>
                                                </form>
                                            }
                                        </div>
                                    }
                                </div>
                        </>
                    }
                </m.div>
                <Footer selected="wallet"/>
            </AnimatePresence>
        </>
    );
}