// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/pictures/store_01.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/pictures/store_02.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/pictures/store_03.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#storeTitle {
  font-family: Silvana;
  font-weight: 300;
  font-size: 60px;
  color: var(--main-link-color);
  margin-top: 0px;
  margin-bottom: 23px;
  line-height: 1em;
}

#header {
  font-weight: normal;
}

#storeText {
	font-size: 16px;
	color: var(--footer-selected-color);
	font-weight: 500;
	margin-bottom: 40px;
}

#storeButton {
	display: block;
	margin-top: 86px;
	margin-bottom: 30px;
}

.storeImage {
	display: flex;
  justify-content: space-between;
	width: 100vw;
	margin-left: -25px;
	background-color: var(--background-color);
  padding-bottom: 30px;
}

#storeImg1 {
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  margin-left: -10%;
}

#storeImg2 {
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

#storeImg3 {
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  margin-right: -10%;
}
.storeImageDetail {
  width: 33.3%;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 30vh;
}
`, "",{"version":3,"sources":["webpack://./src/styles/store.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;CACC,eAAe;CACf,mCAAmC;CACnC,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,cAAc;CACd,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,aAAa;EACZ,8BAA8B;CAC/B,YAAY;CACZ,kBAAkB;CAClB,yCAAyC;EACxC,oBAAoB;AACtB;;AAEA;CACC,yDAA2D;EAC1D,iBAAiB;AACnB;;AAEA;CACC,yDAA2D;AAC5D;;AAEA;CACC,yDAA2D;EAC1D,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,cAAc;EACd,YAAY;AACd","sourcesContent":["#storeTitle {\n  font-family: Silvana;\n  font-weight: 300;\n  font-size: 60px;\n  color: var(--main-link-color);\n  margin-top: 0px;\n  margin-bottom: 23px;\n  line-height: 1em;\n}\n\n#header {\n  font-weight: normal;\n}\n\n#storeText {\n\tfont-size: 16px;\n\tcolor: var(--footer-selected-color);\n\tfont-weight: 500;\n\tmargin-bottom: 40px;\n}\n\n#storeButton {\n\tdisplay: block;\n\tmargin-top: 86px;\n\tmargin-bottom: 30px;\n}\n\n.storeImage {\n\tdisplay: flex;\n  justify-content: space-between;\n\twidth: 100vw;\n\tmargin-left: -25px;\n\tbackground-color: var(--background-color);\n  padding-bottom: 30px;\n}\n\n#storeImg1 {\n\tbackground-image: url('../../public/pictures/store_01.png');\n  margin-left: -10%;\n}\n\n#storeImg2 {\n\tbackground-image: url('../../public/pictures/store_02.png');\n}\n\n#storeImg3 {\n\tbackground-image: url('../../public/pictures/store_03.png');\n  margin-right: -10%;\n}\n.storeImageDetail {\n  width: 33.3%;\n  background-size: auto 100%;\n  background-position: center;\n  background-repeat: no-repeat;\n  display: block;\n  height: 30vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
