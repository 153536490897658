import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState,} from "react";
import '../../styles/subscribe.css';
import {motion as m} from "framer-motion"
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import Select from 'react-select';
import HelperService from "../Services/Bll/HelperService";
import ClientService from "../Services/Bll/ClientService";
import SelectLanguageComponent from "../components/SelectLanguageComponent";
import OwnService from "../Services/Bll/OwnService";
import LoginService from "../../StarterKit/Service/Bll/LoginService";

export default function ActivatePage() {
    let { client } = useParams()

    const ownService = new OwnService()
    const settingService = new SettingService()
    const clientService = new ClientService()
    const loginService = new LoginService();

    const [form, setForm] = useState({});
    const [mails, setMails] = useState(null);
    const [phones, setPhones] = useState(null);
    const [addresses, setAddresses] = useState(null);
    const [optinTypes, setOptinTypes] = useState([]);
    const [confirmation, setConfirmation] = useState('');
    const [settings, setSettings] = useState(null);
    const [regexPw, setRegexPw] = useState('');
    const [errors, setErrors] = useState({});
    const [langLoaded, setLangLoaded] = useState(false);
    const [mustCreatePassword, setMustCreatePassword] = useState(true);
    const [confirm, setConfirm] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        cgv: false,
        rgpd: false
    });


    const navigate = useNavigate()
    async function fetchData() {
        var helperService = new HelperService()
        if (sessionStorage.length === 0){
            await helperService.setLocalStorage();
        }

        let optInTypesResult = await settingService.getFromGroup('optInTypes');
        setSettings(Object.keys(optInTypesResult.getData()));

        let appSettingsResult = await settingService.getFromGroup('app');
        let setting = appSettingsResult.getData();
        let regex = new RegExp(setting.passwordValidation.text);
        setRegexPw(regex);

        let clientResponse = await clientService.read(client);
        let clientData = clientResponse.getData();

        if (clientResponse.getCode() != 0 || clientData.flagEnabled == 1) {
            navigate("/login");
        }
        await HelperService.selectLanguage(clientData.defaultLanguage);

        setMustCreatePassword(clientData.mustCreatePassword);
        if (!clientData.mustCreatePassword) {
            let resConfirm = await clientService.confirm(clientData.key);
            if (resConfirm.getCode() === 0) {
                setConfirm(true)
            } else {
                navigate('/')
            }
        }

        setLangLoaded(true);
        setForm({
            name : clientData.name,
            lastname : clientData.lastName,
            age : -1,
            mails : clientData.mails,
            phones : clientData.phones,
            addresses : clientData.addresses,
            title : clientData.title
        });
        setMails(clientData.mails);
        setPhones(clientData.phones);
        setAddresses(clientData.addresses);
    }

    useEffect(() => {
        fetchData()
    }, [])
    const validate = (data) => {
        const newErrors = {};

        if(!data.password || data.password !== confirmation) {
            newErrors.password = CacheExtension.getDataFromCache("PasswordDoesntMatch")
        }
        if (!regexPw.test(data.password)) {
            newErrors.passwordMatch = CacheExtension.getDataFromCache("PasswordDoesntMatchPattern");
        }

        if(data.mails) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const errors = [];
            Object.keys(data.mails).forEach((email, index) => {
                if (!emailRegex.test(email)) {
                    errors[index] = CacheExtension.getDataFromCache("InvalidEmail");
                }
            });
            if(errors.length){
                newErrors.mails = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidEmail")]
            newErrors.mails = error
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    };

    const submitSubscribe = async (e) => {
        e.preventDefault();

        let optin = {};
        for (const i of optinTypes) {
            optin[i.value] = 1;
        }

        const updatedForm = {
            ...form,
            mails,
            phones: phones ? phones : {},
            addresses: addresses ? addresses : {},
            optIns : optin,
        };
        if(validate(updatedForm)) {
            await ownService.ownAndUpdate(updatedForm, client);
            const user = {
                username: Object.keys(updatedForm.mails)[0],
                password: updatedForm.password,
                service: "front"
            };
            var result = await loginService.login(user);
            if (result.getCode() === 0) {
                navigate('/')
            }
        }
    };

    const handleFormChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    const onChangeSelect = (
        newValue,
        actionMeta
    ) => {
        switch (actionMeta.action) {
            case 'remove-value':
                newValue = optinTypes.filter(item => item !== actionMeta.removedValue)
            case 'clear':
                newValue = []
                break;
        }
        setOptinTypes(newValue);
    };

    const handleCheckboxChange = (event) => {
        setCheckboxes({
            ...checkboxes,
            [event.target.name]: event.target.checked
        });
    };

    const handleChangeEmail = event => {
        if(event.target.value.trim() !== "") {
            setMails({
                [event.target.value] : 1
            });
        } else {
            setMails({});
        }
    }

    const handleChangePhone = event => {
        if(event.target.value.trim() !== "") {
            setPhones({
                [event.target.value] : 1
            });
        } else {
            setPhones({});
        }
    }

    const handleChangeAddress = event => {
        if(event.target.value.trim() !== "") {
            setAddresses({
                [event.target.value] : 1
            });
        } else {
            setAddresses({});
        }
    }

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                {/*<div className={"w-100 d-flex justify-content-end containerLanguage"}>*/}
                {/*    <div className={"selectLanguage"}>*/}
                {/*        <SelectLanguageComponent/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'titleHead container__loginpage'} id="header">*/}
                {/*    <HeaderLogo />*/}
                {/*    <div id="connectPicture"></div>*/}
                {/*</div>*/}
                {langLoaded &&
                    <div id="content">
                        {client && mustCreatePassword &&
                            <>
                                <div id="subscribeIntro" className="textPR">{CacheExtension.getDataFromCache("subscribe.intro")}</div>
                                <form className={'formNew'} onSubmit={(e) => submitSubscribe(e)}>
                                    <input type="text" value={form.name} placeholder={CacheExtension.getDataFromCache("Firstname")} name="name" id="subscribeFirstname" onChange={handleFormChange}/>
                                    {errors.name && <div className="showError">{errors.name}</div>}
                                    <input type="text" value={form.lastname} placeholder={CacheExtension.getDataFromCache("Lastname")} name="lastname" id="subscribeLastname" onChange={handleFormChange}/>
                                    {errors.lastname && <div className="showError">{errors.lastname}</div>}
                                    <input type="email" disabled={true} value={form.mails && Object.keys(form.mails).find(key => form.mails[key] === 1)} placeholder={CacheExtension.getDataFromCache("email")} name="email" id="email" onChange={handleChangeEmail}/>
                                    {errors.mails && <div className="showError">{errors.mails}</div>}
                                    <input className={errors.passwordMatch && "inputError"} type="password" placeholder={CacheExtension.getDataFromCache("Password")} name="password" id="subscribePassword" onChange={handleFormChange}/>
                                    <input className={errors.passwordMatch && "inputError"} type="password" placeholder={CacheExtension.getDataFromCache("ConfirmPassword")} name="confirmation" id="subscribeConfirmation" onChange={(e)=> setConfirmation(e.target.value)}/>
                                    <div>{CacheExtension.getDataFromCache("PasswordDoesntMatchPattern")}</div>
                                    {errors.password && <div className="showError">{errors.password}</div>}
                                    <input type="tel" value={form.phones && Object.keys(form.phones).find(key => form.phones[key] === 1)} placeholder={CacheExtension.getDataFromCache("phone")} name="phone" id="phone" onChange={handleChangePhone} pattern="[0-9+]*"
                                           onKeyDown={(event) => {
                                               if (!/[0-9+]/.test(event.key) && ![ 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight' ].includes(event.key)) {
                                                   event.preventDefault();
                                               }
                                           }}/>
                                    {errors.phones && <div className="showError">{errors.phones}</div>}
                                    <input type="text" value={form.addresses && Object.keys(form.addresses).find(key => form.addresses[key] === 1)} placeholder={CacheExtension.getDataFromCache("address")} name="address" id="address" onChange={handleChangeAddress}/>
                                    {errors.addresses && <div className="showError">{errors.addresses}</div>}

                                    {/*<input type="number" placeholder={CacheExtension.getDataFromCache("Age")} name="age" id="age" onChange={handleFormChange}/>*/}
                                    {/*{errors.age && <div className="showError">{errors.age}</div>}*/}
                                    {/*<input type="text" value={form.title} placeholder={CacheExtension.getDataFromCache("PersonTitle")} name="title" id="title" onChange={handleFormChange}/>*/}
                                    {/*{errors.title && <div className="showError">{errors.title}</div>}*/}


                                    {/*<InputComponent disabled={true} onInputChange={(values) => setMails(values)} defaultValues={form.mails} placeholder={'email'} type={'email'} errors={errors.mails}/>*/}
                                    {/*<InputComponent onInputChange={(values) => setPhones(values)} defaultValues={form.phones} placeholder={'phone'} type={'tel'} errors={errors.phones}/>*/}
                                    {/*<InputComponent onInputChange={(values) => setAddresses(values)} defaultValues={form.addresses} placeholder={'address'} type={'text'} errors={errors.addresses}/>*/}
                                    <Select
                                        isMulti
                                        name="optin"
                                        className={'d-none'}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                neutral20: 'rgb(255, 158, 23)',
                                                neutral30: 'rgb(255, 158, 23)',
                                                primary25: 'rgba(255, 158, 23, 0.5)',
                                                danger: 'rgb(255, 158, 23)',
                                                dangerLight: 'rgba(255, 158, 23, 0.2)',
                                                primary: 'black',
                                            },
                                        })}
                                        placeholder={"notifications.select"}
                                        options={settings && settings.map((key, i) => ({value: key, label: CacheExtension.getDataFromCache(key)}))}
                                        onChange={onChangeSelect}
                                    />
                                    <div className="form-check">
                                        <input style={{width:"auto"}} className="form-check-input" type="checkbox" name="newsletter" id="newsletter"/>
                                        <label className="form-check-label" htmlFor="newsletter">
                                            {CacheExtension.getDataFromCache("newsletter")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            style={{width:"auto"}}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="cgv"
                                            id="cgv"
                                            checked={checkboxes.cgv}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="cgv">
                                            * {CacheExtension.getDataFromCache("cgv")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            style={{width:"auto"}}
                                            className="form-check-input"
                                            type="checkbox"
                                            name="rgpd"
                                            id="rgpd"
                                            checked={checkboxes.rgpd}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="rgpd">
                                            * {CacheExtension.getDataFromCache("rgpd")}
                                        </label>
                                    </div>
                                    <button id="subscribeButton" disabled={!checkboxes.cgv || !checkboxes.rgpd} className="button" type="submit">{CacheExtension.getDataFromCache("subscribe.signon")}</button>
                                </form>
                            </>
                        }
                        {client && !mustCreatePassword && confirm &&
                            <form className={'formNew'}>
                                <div className={"mb-5"}>{CacheExtension.getDataFromCache('ActivateSuccessMessage')}</div>
                                <Link className="button text-white" to={`/login`}>{CacheExtension.getDataFromCache("login")}</Link>
                            </form>
                        }
                    </div>
                }
            </m.div>
        </>
    );
}


