// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#updateProfileFirstnameLabel {

}

#updateProfileFirstname {

}

#updateProfileLastnameLabel {

}

#updateProfileLastname {

}

#updateProfilePasswordLabel {

}

#updateProfilePassword {

}

#updateProfileConfirmationLabel {

}

#updateProfileConfirmation {

}

#updateProfileBack {

}

.toto {
    background: beige;
    width: 100%;
    height: 100%;
}

#updateProfileButton {

}`, "",{"version":3,"sources":["webpack://./src/styles/updateProfile.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;AAEA;;AAEA","sourcesContent":["#updateProfileFirstnameLabel {\n\n}\n\n#updateProfileFirstname {\n\n}\n\n#updateProfileLastnameLabel {\n\n}\n\n#updateProfileLastname {\n\n}\n\n#updateProfilePasswordLabel {\n\n}\n\n#updateProfilePassword {\n\n}\n\n#updateProfileConfirmationLabel {\n\n}\n\n#updateProfileConfirmation {\n\n}\n\n#updateProfileBack {\n\n}\n\n.toto {\n    background: beige;\n    width: 100%;\n    height: 100%;\n}\n\n#updateProfileButton {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
