import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiClient from "../Api/ApiClient";

export default class ClientService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiClient();
        this.#helper = new HelperService();
    }

    async subscribe(data) {
        const result = await this.#api.subscribe(data);
        return result;
    }

    async read(key) {
        const result = await this.#api.read(key);
        return result;
    }

    async checkEnabled(key = null, email = null) {
        const result = await this.#api.checkEnabled(key, email = null);
        return result;
    }

    async confirm(client) {
        const result = await this.#api.confirm(client);
        return result;
    }

    async resendActivationLink(email) {
        const result = await this.#api.resendActivationLink(email);
        return result;
    }

    async update(key, data) {
        const result = await this.#api.update(key, data);
        return result;
    }

    async anonymize(key) {
        const result = await this.#api.anonymize(key);
        return result;
    }

    async delete(key) {
        const result = await this.#api.delete(key);
        return result;
    }
}