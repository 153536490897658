import {Link, Navigate, useNavigate} from "react-router-dom";
import { Footer } from "../components/footerComponent";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import '../../styles/updateCoordinates.css';
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ClientService from "../Services/Bll/ClientService";
import React, {useEffect, useState} from "react";
import InputComponent from "../components/InputComponent";
import HelperService from "../Services/Bll/HelperService";

export default function UpdateCoordinatesPage() {
    const service = new ClientService()

    const [data, setData] = useState(null);
    const [mails, setMails] = useState(null);
    const [phones, setPhones] = useState(null);
    const [addresses, setAddresses] = useState(null);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        service.read(sessionStorage['lastname']).then(function (result) {
            let res = result.getData();
            setData(res);
            setMails(res.mails)
            setPhones(res.phones)
            setAddresses(res.addresses)
        })
    },[])

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }
    const validate = (data) => {
        const newErrors = {};

        if(data.mails) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const errors = [];
            Object.keys(data.mails).forEach((email, index) => {
                if (!emailRegex.test(email)) {
                    errors[index] = CacheExtension.getDataFromCache("InvalidEmail");
                }
            });
            if(errors.length){
                newErrors.mails = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidEmail")]
            newErrors.mails = error
        }

        // if(data.phones) {
        //     const phoneRegex = /^\d{10}$/;
        //     const errors = [];
        //     Object.keys(data.phones).forEach((phone, index) => {
        //         if (!phoneRegex.test(phone)) {
        //             errors[index] = CacheExtension.getDataFromCache("InvalidPhone");
        //         }
        //     });
        //     if(errors.length){
        //         newErrors.phones = errors;
        //     }
        // } else {
        //     let error = [CacheExtension.getDataFromCache("InvalidPhone")]
        //     newErrors.phones = error
        // }

        // if(data.addresses) {
        //     const errors = [];
        //     Object.keys(data.addresses).forEach((address, index) => {
        //         if (!data.addresses || data.addresses === "") {
        //             errors[index] = CacheExtension.getDataFromCache("InvalidAddress");
        //         }
        //     });
        //     if(errors.length){
        //         newErrors.addresses = errors;
        //     }
        // } else {
        //     let error = [CacheExtension.getDataFromCache("InvalidAddress")]
        //     newErrors.addresses = error
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const submit = async (e) => {
        e.preventDefault()

        const updatedForm = {
            mails,
            phones,
            addresses,
        };
        if(validate(updatedForm)) {
            await service.update(data.key, updatedForm)
            navigate('/profile')
        }
    }

    const handleChangeEmail = event => {
        if(event.target.value.trim() !== "") {
            setMails({
                [event.target.value] : 1
            });
        } else {
            setMails({});
        }
    }

    const handleChangePhone = event => {
        if(event.target.value.trim() !== "") {
            setPhones({
                [event.target.value] : 1
            });
        } else {
            setPhones({});
        }
    }

    const handleChangeAddress = event => {
        if(event.target.value.trim() !== "") {
            setAddresses({
                [event.target.value] : 1
            });
        } else {
            setAddresses({});
        }
    }

  return (
    <>
        <m.div className="container__animation"
               initial={{width:'100vw' , marginLeft : '100vw' }}
               animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
               exit={{ marginLeft : '100vw', x: "inherit" }}
               transition={{duration: 0.30, ease: "linear"}}>
            {data &&
                <>
                    <div className={'titleHead'} id="header">
                        <Link className="backLink" id="updateCoordinatesBack" to={`/profile`}><BackIcon className="chevronBack"/>{CacheExtension.getDataFromCache("updateCoordinates.back")}</Link>
                        <h1 id="productTokenTitle">{CacheExtension.getDataFromCache("updateCoordinates.title")}</h1>
                    </div>
                    <div id="content">
                        <form onSubmit={(e) => submit(e)}>
                            <span className="labelInput" id="updateCoordinateEmailLabel">{CacheExtension.getDataFromCache("register.email")}</span>
                            <input type="email" value={mails && Object.keys(mails).find(key => mails[key] === 1)} placeholder={CacheExtension.getDataFromCache("email")} name="email" id="email" onChange={handleChangeEmail}/>
                            {errors.mails && <div className="showError">{errors.mails}</div>}
                            <span className="labelInput" id="updateCoordinatePhoneLabel">{CacheExtension.getDataFromCache("updateCoordinates.phone")}</span>
                            <input type="tel" value={phones && Object.keys(phones).find(key => phones[key] === 1)} placeholder={CacheExtension.getDataFromCache("phone")} name="phone" id="phone" onChange={handleChangePhone} pattern="[0-9+]*"
                                   onKeyDown={(event) => {
                                       if (!/[0-9+]/.test(event.key) && ![ 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight' ].includes(event.key)) {
                                           event.preventDefault();
                                       }
                                   }}/>
                            {errors.phones && <div className="showError">{errors.phones}</div>}
                            <span className="labelInput" id="updateCoordinateAddressesLabel">{CacheExtension.getDataFromCache("updateCoordinates.address")}</span>
                            <input type="text" value={addresses && Object.keys(addresses).find(key => addresses[key] === 1)} placeholder={CacheExtension.getDataFromCache("address")} name="address" id="address" onChange={handleChangeAddress}/>
                            {errors.addresses && <div className="showError">{errors.addresses}</div>}
                            <button id="updateCoordinatesButton"  className="button buttonsubmit" type="submit">{CacheExtension.getDataFromCache("updateCoordinates.update")}</button>


                            {/*<span className="labelInput" id="updateCoordinateEmailLabel">{CacheExtension.getDataFromCache("register.email")}</span>*/}
                            {/*<InputComponent onInputChange={(values) => setMails(values)} type={'email'} errors={errors.mails} defaultValues={data.mails} placeholder={'email'}/>*/}
                            {/*<span className="labelInput" id="updateCoordinatePhoneLabel">{CacheExtension.getDataFromCache("updateCoordinates.phone")}</span>*/}
                            {/*<InputComponent onInputChange={(values) => setPhones(values)} type={'phone'} errors={errors.phones} defaultValues={data.phones} placeholder={'phone'}/>*/}
                            {/*<span className="labelInput" id="updateCoordinateAddressesLabel">{CacheExtension.getDataFromCache("updateCoordinates.address")}</span>*/}
                            {/*<InputComponent onInputChange={(values) => setAddresses(values)} type={'address'} errors={errors.addresses} defaultValues={data.addresses} placeholder={'address'}/>*/}
                        </form>
                    </div>
                </>
            }
        </m.div>
        <Footer selected="profile"/>
    </>
  );
}

