// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__entretien {
    margin: 0;
    padding: 0;
}

#productentretienEntry {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: block;
    position: relative;
    display: flex;
}

#entretienTitle {
    font-family: Silvana;
    font-weight: 300;
    font-size: 60px;
    color: var(--main-link-color);
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 1em;
}

#productentretienEntry #productEntretienTag {
    display: block;
    border-radius: 5px;
    width: 10px;
    height: 10px;
    margin-right: 18px;
    background-color: var(--main-hg-color);
    position: relative;
    margin-top: 17px;
}

#productentretienEntry:after {
    content: "";
    width: 100vw;
    background: #DEDEDE;
    left: -25px;
    bottom: 0;
    height: 0.5px;
    position: absolute;
}

#productentretienEntry:last-child:after {
    content: inherit;
    height: 0;
}

.entretienContainerDay {
    display: flex;
}

.entretienContainerDay span {
    margin-right: 8px;
}
.mapEntretien {
    max-width: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/styles/entretien.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;IACf,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sCAAsC;IACtC,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,SAAS;IACT,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".container__entretien {\n    margin: 0;\n    padding: 0;\n}\n\n#productentretienEntry {\n    padding-bottom: 20px;\n    margin-bottom: 20px;\n    display: block;\n    position: relative;\n    display: flex;\n}\n\n#entretienTitle {\n    font-family: Silvana;\n    font-weight: 300;\n    font-size: 60px;\n    color: var(--main-link-color);\n    margin-top: 0;\n    margin-bottom: 40px;\n    line-height: 1em;\n}\n\n#productentretienEntry #productEntretienTag {\n    display: block;\n    border-radius: 5px;\n    width: 10px;\n    height: 10px;\n    margin-right: 18px;\n    background-color: var(--main-hg-color);\n    position: relative;\n    margin-top: 17px;\n}\n\n#productentretienEntry:after {\n    content: \"\";\n    width: 100vw;\n    background: #DEDEDE;\n    left: -25px;\n    bottom: 0;\n    height: 0.5px;\n    position: absolute;\n}\n\n#productentretienEntry:last-child:after {\n    content: inherit;\n    height: 0;\n}\n\n.entretienContainerDay {\n    display: flex;\n}\n\n.entretienContainerDay span {\n    margin-right: 8px;\n}\n.mapEntretien {\n    max-width: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
