import SettingService from "../../../StarterKit/Service/Bll/SettingService";
import {useNavigate} from "react-router-dom";

export default class HelperService {

    async setLocalStorage() {
        const serviceSettings = new SettingService();

        try {
            if (!sessionStorage.getItem('language') || sessionStorage.getItem('language') == undefined) {
                sessionStorage.setItem('language', 'FR');
            }
            if (!localStorage.getItem('locale')) {
                localStorage.setItem('locale', sessionStorage['language'] ? sessionStorage['language'] : 'FR');
            }
            // language always set to fr
            // localStorage.setItem('locale', 'FR');
            // sessionStorage.setItem('language', 'FR');
            if (!localStorage.getItem('securityZone')) {
                var securityZone = await serviceSettings.getFromGroup("securityZone");
                localStorage.setItem(securityZone.context.group, JSON.stringify(securityZone.getData()));
            }
            var resultCache = await serviceSettings.getFromGroup("cacheFront");
            var settings = resultCache.getData();
            if (Object.keys(settings).length > 0) {
                for (const settingsKey in settings) {
                    if (!localStorage.getItem(settingsKey) || settingsKey == 'labelFront') {
                        var resultSettings = await serviceSettings.getFromGroup(settingsKey);
                        localStorage.setItem(settingsKey, JSON.stringify(resultSettings.getData()));
                    }
                }
            }
        } catch (e) {
            console.log(e)
            window.location.href = "/login";
        }

    }
    static isLogged() {
        return sessionStorage['isLogged'] === 'yes';
    }

    static async selectLanguage(selected) {
        var serviceSettings = new SettingService();
        // language always set to fr
        // sessionStorage['language'] = 'FR'
        // localStorage['locale'] = 'FR'
        sessionStorage['language'] = selected
        localStorage['locale'] = selected
        var resultSettings = await serviceSettings.getFromGroup("labelFront");
        localStorage.setItem("labelFront", JSON.stringify(resultSettings.getData()));

    }

}
