import {BaseApiService} from "../BaseApiService";

/*******************************************************************
 * Diji Starter Kit
 *
 * ApiLogin
 *******************************************************************/
export default class ApiLogin extends BaseApiService {
    /*******************************************************************
     * public function
     *******************************************************************/
    async login(data) {
        this.setPostMethod();
        this.setUnsecure();
        this.callNeedDataInBody();
        this.setApiRoute("login_check");
        this.setBody(data);
        this.setShowReturn();
        this.setNoLogoutOnError()
        return await this.callApi();
    }

    /********************/
    recover(data) {
        this.setPostMethod();
        this.setSecureByService();
        this.setApiRoute("FR/users/recover");
        this.setBody(data);

        return this.callApi();
    }

    /********************/
    confirm(data) {
        this.setPostMethod();
        this.setSecureByService();
        this.setApiRoute(sessionStorage['language'] + "/users/confirm");
        this.setBody(data);
        this.setShowReturn();
        return this.callApi();
    }

    /********************/
    recoverClient(data) {
        this.setPostMethod();
        this.setSecureByService();
        this.setApiRoute(sessionStorage['language'] + "/clients/recover");
        this.setBody(data);
        this.setShowReturn();
        return this.callApi();
    }

    changePasswordRecover(data) {
        this.setPostMethod();
        this.setSecureByService();
        this.setApiRoute("FR/users/changePasswordRecover");
        this.setBody(data);
        this.setShowReturn();
        return this.callApi();
    }

}
