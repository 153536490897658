// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#updateCoordinatesAddressLabel {

}

#updateCoordinatesAddress {

}

#updateCoordinatesPhoneLabel {

}

#updateCoordinatesPhone {

}

#updateCoordinatesButton {

}

#updateCoordinatesBack {

}`, "",{"version":3,"sources":["webpack://./src/styles/updateCoordinates.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA","sourcesContent":["#updateCoordinatesAddressLabel {\n\n}\n\n#updateCoordinatesAddress {\n\n}\n\n#updateCoordinatesPhoneLabel {\n\n}\n\n#updateCoordinatesPhone {\n\n}\n\n#updateCoordinatesButton {\n\n}\n\n#updateCoordinatesBack {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
