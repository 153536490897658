// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#registerIntro {
	font-weight: 500;
	font-size: 16px;
	color: var(--footer-selected-color);
	margin-bottom: 30px;
	display: block;
}

#registerFirstname {
	
}

#registerLastname {
	
}

#registerEmail {
	
}

#registerPassword {
	
}

#registerButton {
	background-color: var(--main-hg-color);
}

#registerLogin {
	margin-left: auto;
	margin-right: auto;
	color: var(--main-link-color);
}

#registerProduct {
	color: var(--footer-selected-color);
	font-size: 35px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
	line-height: 27px;
}

.header__register__product {
	position: relative;
	min-height: 253px;
}
.container__information__register__product {
	flex-direction: column;
	display: flex;
	justify-content: center;
	width: 100%;
	top: -18px;
}
.container__information__register__product svg {
  margin: 7px auto -15px;
	display: block;
}`, "",{"version":3,"sources":["webpack://./src/styles/register.css"],"names":[],"mappings":";AACA;CACC,gBAAgB;CAChB,eAAe;CACf,mCAAmC;CACnC,mBAAmB;CACnB,cAAc;AACf;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;CACC,sCAAsC;AACvC;;AAEA;CACC,iBAAiB;CACjB,kBAAkB;CAClB,6BAA6B;AAC9B;;AAEA;CACC,mCAAmC;CACnC,eAAe;CACf,iBAAiB;CACjB,yBAAyB;CACzB,kBAAkB;CAClB,SAAS;CACT,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;CAClB,iBAAiB;AAClB;AACA;CACC,sBAAsB;CACtB,aAAa;CACb,uBAAuB;CACvB,WAAW;CACX,UAAU;AACX;AACA;EACE,sBAAsB;CACvB,cAAc;AACf","sourcesContent":["\n#registerIntro {\n\tfont-weight: 500;\n\tfont-size: 16px;\n\tcolor: var(--footer-selected-color);\n\tmargin-bottom: 30px;\n\tdisplay: block;\n}\n\n#registerFirstname {\n\t\n}\n\n#registerLastname {\n\t\n}\n\n#registerEmail {\n\t\n}\n\n#registerPassword {\n\t\n}\n\n#registerButton {\n\tbackground-color: var(--main-hg-color);\n}\n\n#registerLogin {\n\tmargin-left: auto;\n\tmargin-right: auto;\n\tcolor: var(--main-link-color);\n}\n\n#registerProduct {\n\tcolor: var(--footer-selected-color);\n\tfont-size: 35px;\n\tfont-weight: bold;\n\ttext-transform: uppercase;\n\ttext-align: center;\n\tmargin: 0;\n\tline-height: 27px;\n}\n\n.header__register__product {\n\tposition: relative;\n\tmin-height: 253px;\n}\n.container__information__register__product {\n\tflex-direction: column;\n\tdisplay: flex;\n\tjustify-content: center;\n\twidth: 100%;\n\ttop: -18px;\n}\n.container__information__register__product svg {\n  margin: 7px auto -15px;\n\tdisplay: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
