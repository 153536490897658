// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.uploadComponent {
    outline: 2px dashed rgb(255, 158, 23);
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .4s linear;
    font-size: 1.25rem;
    background-color: white;
    position: relative;
    width: 100%;
    font-family: OpenSans;
    font-weight: 300;
    color: var(--main-link-color);
}

.uploadComponent:hover {
    background-color: rgba(255, 158, 23, 0.5);
}

.uploadComponent strong {
    color: #000;
}

.uploadComponent input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    padding: 0px;
}

.uploadComponent label {
    width: 100%;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/StarterKit/css/import.css"],"names":[],"mappings":";;AAEA;IACI,qCAAqC;IACrC,qBAAqB;IAErB,wEAAwE;IACxE,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":["\n\n.uploadComponent {\n    outline: 2px dashed rgb(255, 158, 23);\n    outline-offset: -10px;\n    -webkit-transition: outline-offset .15s ease-in-out, background-color .4s linear;\n    transition: outline-offset .15s ease-in-out, background-color .4s linear;\n    font-size: 1.25rem;\n    background-color: white;\n    position: relative;\n    width: 100%;\n    font-family: OpenSans;\n    font-weight: 300;\n    color: var(--main-link-color);\n}\n\n.uploadComponent:hover {\n    background-color: rgba(255, 158, 23, 0.5);\n}\n\n.uploadComponent strong {\n    color: #000;\n}\n\n.uploadComponent input[type=\"file\"] {\n    width: 0.1px;\n    height: 0.1px;\n    padding: 0px;\n}\n\n.uploadComponent label {\n    width: 100%;\n    cursor: pointer;\n    padding-top: 20px;\n    padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
