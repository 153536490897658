import { Link } from "react-router-dom";
import { HeaderLogo } from "../components/headerLogoComponent";
import '../../styles/login.css'
import '../../styles/register.css';
import {motion as m} from "framer-motion"
import LoginService from "../../StarterKit/Service/Bll/LoginService";
import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import Consts from "../Consts";
import SelectLanguageComponent from "../components/SelectLanguageComponent";
import SettingService from "../../StarterKit/Service/Bll/SettingService";

export default function LoginPage() {
    const navigate = useNavigate();
    var service = new LoginService();
    const settingService = new SettingService()

    const [openForget, setOpenForget] = useState(false);
    const [formRecover, setFormRecover] = useState(null);
    const [errors, setErrors] = useState({});
    const [regexPw, setRegexPw] = useState('');
    const [checkMailMessage, setCheckMailMessage] = useState(false);
    const [errorForget, setErrorForget] = useState(false);

    const handleCloseForget = () => {
        setOpenForget(false);
    };

    const handleOpenForget = () => {
        setOpenForget(true);
    };

    var url = JSON.parse(localStorage.getItem(Consts.GROUP_HOME));
    React.useEffect(() => {
        if (sessionStorage['isLogged'] !== undefined && sessionStorage['isLogged'] === 'yes'){
            navigate(url[sessionStorage['profile']].text)
        }

        settingService.getFromGroup('app').then(function (result) {
            let setting = result.getData()
            let regex = new RegExp(setting.passwordValidation.text)
            setRegexPw(regex)
        })
    },[])

    const handleFormRecoverChange = event => {
        if(event.target.name == "username") {
            setFormRecover({
                ...formRecover,
                [event.target.name]:event.target.value.toLowerCase()
            });
        } else {
            setFormRecover({
                ...formRecover,
                [event.target.name]:event.target.value
            });
        }
    }
    const submitRecover = async (e) => {
        e.preventDefault();
        setErrorForget(false)
        if(formRecover) {
            let res = await service.recoverClient(formRecover);
            if(res.getCode() === 0 ) {
                setCheckMailMessage(true)
            } else {
                setErrorForget(true)
            }
        }
    };
    const  handleSubmit = async event => {
        event.preventDefault();
        const user = {
            username: document.getElementById('loginEmail').value.toLowerCase(),
            password: document.getElementById('loginPassword').value,
            service: "front"
        };
        const newErrors = {};

        if (!regexPw.test(user.password)) {
            newErrors.passwordLogin = CacheExtension.getDataFromCache("PasswordDoesntMatchPattern");
            setErrors(newErrors);
        } else {
            setErrors(newErrors);
            var result = await service.login(user);
            if (result.getCode() === 0) {
                navigate('/')
            }
        }

    }

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}} style={{overflowX:'hidden'}}>
                <div className={"w-100 d-flex justify-content-end containerLanguage"}>
                    <div className={"selectLanguage"}>
                        <SelectLanguageComponent/>
                    </div>
                </div>
                <div className={'titleHead container__loginpage '} id="header">
                    <HeaderLogo />
                    <h1 id="loginTitle" className="absoluteTitle">{CacheExtension.getDataFromCache("login.title")}</h1>

                </div>

                <div style={{padding:'20px'}}>
                    <form  onSubmit={handleSubmit} >
                        <input type="email" name="email" placeholder={CacheExtension.getDataFromCache("login.email")} id="loginEmail" />
                        <div id="loginPasswordContainer">
                            <input type="password" name="password" placeholder={CacheExtension.getDataFromCache("login.password")} id="loginPassword"/>
                            <Link id="loginForgotten" onClick={handleOpenForget}>{CacheExtension.getDataFromCache("login.forgotten")}</Link>
                        </div>
                        {errors.passwordLogin && <div className="showError">{errors.passwordLogin}</div>}
                        <button id="loginButton" className="button text-center" type="submit">{CacheExtension.getDataFromCache("login.login")}</button>
                        <Link className={'button btnReverse'} to={`/subscribe`}>{CacheExtension.getDataFromCache("login.signon")}</Link>
                    </form>
                </div>
                {openForget && (
                    <div className="modal-container">
                        <div className="modal-overlay" onClick={handleCloseForget}></div>
                        <div className="modal-dialog w-75" style={{maxWidth: "none"}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{CacheExtension.getDataFromCache('ForgottenTitle')}</h5>
                                    <button type="button" className="close" onClick={handleCloseForget}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={(e) => submitRecover(e)}>
                                    <div className="modal-body">
                                        <div id="confirmUser_Form">
                                            <div id="confirmUser_Group_confirmToken_email" className="form-inline mb-2">
                                                <label htmlFor="confirmUser_Control_confirmToken_email"
                                                       className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                                       style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("email")}</label>
                                                <input id="confirmUser_Control_confirmToken_email" type="email" className="col-8 form-control sk-auto-form sk-form-email" name="username" onChange={handleFormRecoverChange} />
                                                {errors.mails && <div className="showError">{errors.mails}</div>}
                                                {errorForget && <div className="showError">{CacheExtension.getDataFromCache("errorForget")}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'modal-footer'}>
                                        <div className={'d-flex justify-content-center align-items-center'}>
                                            <div>
                                                <button disabled={checkMailMessage} type='submit' className={'btn btnBo submit'}>{CacheExtension.getDataFromCache("Validate")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {checkMailMessage &&
                                    <div className="modal-body">
                                        <div>{CacheExtension.getDataFromCache('CheckYourMails')}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </m.div>
        </>
    );
}
