// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tutorialBlock {
  
}

#tutorialBlockIntro {
  
}

#tutorialBlockModel {
  
}

#tutorialWelcomeText {
  
}

#tutorialWalletButton {
	
}

#tutorialBlockPicture {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  height: 15vh;
}`, "",{"version":3,"sources":["webpack://./src/styles/tutorial.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;EAC3B,wBAAwB;EACxB,cAAc;EACd,YAAY;AACd","sourcesContent":["#tutorialBlock {\n  \n}\n\n#tutorialBlockIntro {\n  \n}\n\n#tutorialBlockModel {\n  \n}\n\n#tutorialWelcomeText {\n  \n}\n\n#tutorialWalletButton {\n\t\n}\n\n#tutorialBlockPicture {\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  display: block;\n  height: 15vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
