
import HelperService from "./HelperService";
import ApiUsers from "../Api/ApiUsers";


export default class UsersService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiUsers();
        this.#helper = new HelperService();
    }


    async list(search = "", state = "", profile = "", language = "", page = 1) {
        console.debug(`[Diji SK] - Service - UserService - search : ${search}, state : ${state}, profile : ${profile}, language : ${language}, page : ${page}`);
        const result = await this.#api.getUsers( search, state, profile, language, page);
        return result;
    }

    async read(id) {
        console.debug(`[Diji SK] - Service - UserService - read : ${id}`);
        const result = await this.#api.get(id);
        return result;
    }

    async me(user) {
        console.debug(`[Diji SK] - Service - UserService - Me`);
        const result = await this.#api.me(user);
        return result;
    }

    async update(id, user) {
        console.debug(`[Diji SK] - Service - UserService - update ${id}`);
        const result = await this.#api.updateUser(id, user);
        return result;
    }

    async add(user) {
        console.debug(`[Diji SK] - Service - UserService - add ${user.email}`);
        const result = await this.#api.addUser(user);
        return result;
    }

    async enable(id) {
        console.debug(`[Diji SK] - Service - UserService - enable : ${id}`);
        const result = await this.#api.enable(id);
        return result;
    }

    async disable(id) {
        console.debug(`[Diji SK] - Service - UserService - disable : ${id}`);
        const result = await this.#api.disable(id);
        return result;
    }

    async lock(id) {
        console.debug(`[Diji SK] - Service - UserService - lock : ${id}`);
        const result = await this.#api.lock(id);
        return result;
    }

    async unlock(id) {
        console.debug(`[Diji SK] - Service - UserService - unlock : ${id}`);
        const result = await this.#api.unlock(id);
        return result;
    }
}
