import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState,} from "react";
import { HeaderLogo } from "../components/headerLogoComponent";
import '../../styles/subscribe.css';
import {motion as m} from "framer-motion"
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import InputComponent from "../components/InputComponent";
import Select from 'react-select';
import HelperService from "../Services/Bll/HelperService";
import ClientService from "../Services/Bll/ClientService";
import SelectLanguageComponent from "../components/SelectLanguageComponent";
import OwnService from "../Services/Bll/OwnService";
import LoginService from "../../StarterKit/Service/Bll/LoginService";
import ProductService from "../Services/Bll/ProductsService";

export default function ResendActivationLinkPage() {

    const clientService = new ClientService()

    const [errors, setErrors] = useState({});
    const [mail, setMail] = useState();
    const [linkSent, setLinkSent] = useState(false);

    useEffect(() => {
    }, []);

    async function sendActivationLink(e) {
        e.preventDefault()
        let result = await clientService.resendActivationLink(mail);
        if(result.getCode() == 0) {
            setLinkSent(true)
        }
    }

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                <div className={"w-100 d-flex justify-content-end containerLanguage"}>
                    <div className={"selectLanguage"}>
                        <SelectLanguageComponent/>
                    </div>
                </div>
                <div className={'titleHead container__loginpage'} id="header">
                    <HeaderLogo />
                    {/*<div id="connectPicture"></div>*/}
                </div>
                <div id="content">
                    {!linkSent ?
                        <form className={'formNew'} onSubmit={(e) => sendActivationLink(e)}>
                            <div className={"mb-5"}>{CacheExtension.getDataFromCache("resendActivationLink.intro")}</div>
                            <input type="email" placeholder={CacheExtension.getDataFromCache("email")} name="email" onChange={(event) => setMail(event.target.value)}/>
                            {errors.mails && <div className="showError">{errors.mails}</div>}
                            <div className={"w-100 d-flex justify-content-end mb-3"}>
                                <button disabled={!mail} type='submit' className={'btn btnBo submit'}>{CacheExtension.getDataFromCache("Validate")}</button>
                            </div>
                        </form> :
                        <form className={'formNew'}>
                            <div className={"mb-5"}>{CacheExtension.getDataFromCache('resendActivationLink.sent')}</div>
                        </form>
                    }

                </div>
            </m.div>
        </>
    );
}


