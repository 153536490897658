import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState,} from "react";
import { HeaderLogo } from "../components/headerLogoComponent";
import '../../styles/subscribe.css';
import {motion as m} from "framer-motion"
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ClientService from "../Services/Bll/ClientService";
import SelectLanguageComponent from "../components/SelectLanguageComponent";
import OwnService from "../Services/Bll/OwnService";
import LoginService from "../../StarterKit/Service/Bll/LoginService";
import ProductService from "../Services/Bll/ProductsService";

export default function QrLinkPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const product = queryParams.get('product');
    const signature = queryParams.get('signature');

    const ownService = new OwnService()
    const settingService = new SettingService()
    const clientService = new ClientService()
    const loginService = new LoginService();
    const productService = new ProductService();

    const [subscribeLink, setSubscribeLink] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [regexPw, setRegexPw] = useState('');
    const [errors, setErrors] = useState({});
    const [productData, setProductData] = useState({});
    const [mail, setMail] = useState();
    const fetchData = async () => {
        const signRes = await productService.checkSignature(signature);
        let resultSign = signRes.getData();
        if (signRes.getCode() === 0 && resultSign.signatureStatus) {
            settingService.getFromGroup('app').then(function (result) {
                let setting = result.getData()
                let regex = new RegExp(setting.passwordValidation.text)
                setRegexPw(regex)
            })

            const gtinMatch = product.match(/01\/(\d+)\//);
            const serialMatch = product.match(/21\/(\d+)\//);
            const gtin = gtinMatch ? gtinMatch[1] : null;
            const serial = serialMatch ? serialMatch[1] : null;

            const res = await productService.read(gtin, serial);
            if (res.getCode() != 0) {
                navigate('/login');
            }
            setSubscribeLink('/subscribe/'+gtin+'/'+serial)
            let productRes = res.getData();
            setProductData(productRes);

            if (productRes.owner) {
                const r = await clientService.checkEnabled(productRes.owner);
                let clientRes = r.getData();
                if (clientRes.enabled) {
                    navigate('/')
                } else {
                    navigate('/resend')
                }
            }
            setLoaded(true);
        } else {
            navigate('/')
        }
    };

    const navigate = useNavigate()

    useEffect(() => {
        fetchData();
    }, []);

    const  handleSubmit = async event => {
        event.preventDefault();
        const user = {
            username: document.getElementById('loginEmail').value.toLowerCase(),
            password: document.getElementById('loginPassword').value,
            service: "front"
        };
        const newErrors = {};

        if (!regexPw.test(user.password)) {
            newErrors.passwordLogin = CacheExtension.getDataFromCache("PasswordDoesntMatchPattern");
            setErrors(newErrors);
        } else {
            setErrors(newErrors);
            var result = await loginService.login(user);
            if (result.getCode() === 0) {
                await ownService.own(productData.key);
                navigate('/')
            }
        }
    }

    function checkMail() {
        return
    }

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                <div className={"w-100 d-flex justify-content-end containerLanguage"}>
                    <div className={"selectLanguage"}>
                        <SelectLanguageComponent/>
                    </div>
                </div>
                <div className={'titleHead container__loginpage'} id="header">
                    <HeaderLogo />
                    {/*<div id="connectPicture"></div>*/}
                </div>
                <div id="content">
                    {loaded ?
                        <>
                            {!productData.owner &&
                                <>
                                    <div id="subscribeIntro" className="textPR">{CacheExtension.getDataFromCache("qrlink.intro")}</div>
                                    <form onSubmit={handleSubmit} >
                                        <input type="email" name="email" placeholder={CacheExtension.getDataFromCache("login.email")} id="loginEmail" />
                                        <div id="loginPasswordContainer">
                                            <input type="password" name="password" placeholder={CacheExtension.getDataFromCache("login.password")} id="loginPassword"/>
                                        </div>
                                        {errors.passwordLogin && <div className="showError">{errors.passwordLogin}</div>}
                                        <button id="loginButton" className="button text-center" type="submit">{CacheExtension.getDataFromCache("login.login")}</button>
                                        <Link className={'button btnReverse'} to={subscribeLink}>{CacheExtension.getDataFromCache("login.signon")}</Link>
                                    </form>
                                </>
                            }
                        </>
                    :
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                            </div>
                        </div>
                    }
                </div>
            </m.div>
        </>
    );
}


