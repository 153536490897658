import {Link, useNavigate} from "react-router-dom";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import { ReactComponent as QRCodeIcon } from '../../res/QRCode.svg'
import '../../styles/new.css';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import React, {useEffect, useRef, useState} from "react";
import OwnService from "../Services/Bll/OwnService";
import InputComponent from "../components/InputComponent";
import Select from 'react-select';
import HelperService from "../Services/Bll/HelperService";
import SelectLanguageComponent from "../components/SelectLanguageComponent";
import {IconActionButton} from "../components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QrScanner from 'qr-scanner';

export default function NewPage() {

  const ownService = new OwnService()
  const settingService = new SettingService()
  const productService = new ProductService();

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  const [startScan, setStartScan] = useState(false);
  const [serial, setSerial] = useState('');
  const [gtin, setGtin] = useState('');
  const [product, setProduct] = useState(null);
  const [form, setForm] = useState({
    age : 1
  });
  const [mails, setMails] = useState(null);
  const [phones, setPhones] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [optinTypes, setOptinTypes] = useState([]);
  const [confirmation, setConfirmation] = useState('');
  const [settings, setSettings] = useState(null);
  const [errors, setErrors] = useState({});
  const [checkboxes, setCheckboxes] = useState({
    cgv: false,
    rgpd: false
  });

  const isQrCodeScanExecuted = useRef(false);

  const navigate = useNavigate();

  const logged = HelperService.isLogged();

  const qrCodeScan = async (data) => {
    if(data) {
      var regex = /\/21\/(\d+)\//;
      var match = data.match(regex);
      var serial = match && match[1];

      var regexGtin = /\/01\/(\d+)\//;
      var matchGtin = data.match(regexGtin);
      var gtin = matchGtin && matchGtin[1];

      var result = await productService.read(gtin, serial)
      if (Object.keys(result.data).length > 0) {
        setProduct(result.data)
        $("#scanSerial").val(result.data.serial)
        setSerial(result.data.serial)

        $("#scanGtin").val(result.data.model.gtinSku)
        setGtin(result.data.model.gtinSku)
      }
      return result
    }
  }

  ///////////////////////////////////////////////////
  useEffect(() => {

    const video = document.getElementById('qr-video');
    const camList = document.getElementById('cam-list');
    async function setResult (result) {
      if (!isQrCodeScanExecuted.current) {
        isQrCodeScanExecuted.current = true;
        let res = await qrCodeScan(result.data);
        if(res.getCode() !== 0 ) {
          isQrCodeScanExecuted.current = false;
        }
      }
    }

    const scanner = new QrScanner(video, result => setResult(result), {
      onDecodeError: error => {
        // camQrResult.textContent = error;
        // camQrResult.style.color = 'inherit';
      },
      highlightScanRegion: true,
      highlightCodeOutline: true,
      inversionMode: 'both'
    });

    const updateFlashAvailability = () => {
      scanner.hasFlash().then(hasFlash => {
        let flashToggle = $('#flash-toggle');
        flashToggle.css('display', hasFlash ? 'inline-block' : 'none');
        flashToggle.removeClass('flashOn');
      });
    };

    scanner.start().then(() => {
      updateFlashAvailability();
    });
    // for debugging
    window.scanner = scanner;

    camList.addEventListener('change', event => {
      scanner.setCamera(event.target.value).then(updateFlashAvailability);
    });
  }, []);

  useEffect(() => {
    var helperService = new HelperService()
    if (sessionStorage.length === 0){
      helperService.setLocalStorage();
    }

    settingService.getFromGroup('optInTypes').then(function (result) {
      setSettings(Object.keys(result.getData()))
    })

  }, [])

  const addNewProduct = async (e) => {
    e.preventDefault();
    if (!logged) {
      var formNew = document.querySelector('.formNew')
      var content = document.getElementById('content')
      formNew.style.display = "flex"
      content.style.display = 'none'
      return
    }

    let res = await productService.read(gtin, serial);
    let product = res.getData().key;

    if(product) {
      await ownService.own(product);
      navigate('/')
    }
  }

  const validate = (data) => {
    const newErrors = {};

    if (!data.name || data.name === "") {
      newErrors.name = CacheExtension.getDataFromCache("InvalidFirstname")
    }
    if (!data.lastname || data.lastname === "") {
      newErrors.lastname = CacheExtension.getDataFromCache("InvalidLastname")
    }
    if(!data.password || data.password !== confirmation) {
      newErrors.password = CacheExtension.getDataFromCache("PasswordDoesntMatch")
    }

    if(data.mails) {
      const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const errors = [];
      Object.keys(data.mails).forEach((email, index) => {
        if (!emailRegex.test(email)) {
          errors[index] = CacheExtension.getDataFromCache("InvalidEmail");
        }
      });
      if(errors.length){
        newErrors.mails = errors;
      }
    } else {
      let error = [CacheExtension.getDataFromCache("InvalidEmail")]
      newErrors.mails = error
    }

    if(data.phones) {
      const phoneRegex = /^\d{10}$/;
      const errors = [];
      Object.keys(data.phones).forEach((phone, index) => {
        if (!phoneRegex.test(phone)) {
          errors[index] = CacheExtension.getDataFromCache("InvalidPhone");
        }
      });
      if(errors.length){
        newErrors.phones = errors;
      }
    } else {
      let error = [CacheExtension.getDataFromCache("InvalidPhone")]
      newErrors.phones = error
    }

    // if(data.addresses) {
    //   const errors = [];
    //   Object.keys(data.addresses).forEach((address, index) => {
    //     if (!data.addresses || data.addresses === "") {
    //       errors[index] = CacheExtension.getDataFromCache("InvalidAddress");
    //     }
    //   });
    //   if(errors.length){
    //     newErrors.addresses = errors;
    //   }
    // } else {
    //   let error = [CacheExtension.getDataFromCache("InvalidAddress")]
    //   newErrors.addresses = error
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitSubscribe = async (e) => {
    e.preventDefault();

    let optin = {};
    for (const i of optinTypes) {
      optin[i.value] = 1;
    }

    const updatedForm = {
      ...form,
      hasAcceptedLegalMentions: true,
      mails,
      phones,
      addresses:{},
      optIns : optin,
    };

    if(validate(updatedForm)) {
      let res = await productService.read(gtin, serial);
      let product = res.getData().key;

      await ownService.ownAndCreate(updatedForm, product);
      window.location.reload()
    }
  };



  const qrCodeError = (err) => {
    console.error (err);
  }

  const handleFormChange = event => {
    setForm({
      ...form,
      [event.target.name]:event.target.value
    });
  }

  const handleChangeEmail = event => {
    setMails({
      [event.target.value] : 1
    });
  }

  const handleChangePhone = event => {
    setMails({
      [event.target.value] : 1
    });
  }

  const onChangeSelect = (
      newValue,
      actionMeta
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
        newValue = optinTypes.filter(item => item !== actionMeta.removedValue)
      case 'clear':
        newValue = []
        break;
    }
    setOptinTypes(newValue);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked
    });
  };

  function toggleShowQrReader() {
    if (startScan) {
      $('#reader-container').addClass('d-none');
    } else {
      $('#reader-container').removeClass('d-none');
    }
    setStartScan(!startScan);
  }

  return (
      <>
        <AnimatePresence>
          <m.div key="container__animation"
                 className="container__animation paddingBottom parent-container"
                 initial={{width:'100vw' , marginLeft : '100vw' }}
                 animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                 exit={{ marginLeft : '100vw', x: "inherit" }}
                 transition={{duration: 0.30, ease: "linear"}}>
            <div className={'titleHead'} id="header">
              <div>
                <Link id="newBack" to={ `/` }><BackIcon className="chevronBack"/>{CacheExtension.getDataFromCache("new.back")}</Link>
                <div className={"newLanguage selectLanguage"}>
                  <SelectLanguageComponent/>
                </div>
              </div>
              <h1 id="newTitle">{CacheExtension.getDataFromCache("new.title")}</h1>
            </div>

            <div id="content">
              <span id="newIntro">{CacheExtension.getDataFromCache("new.intro")}</span>

              <div id={"reader-container"}>

                <div className="topQrModal">
                  <select style={{height:"50px"}} id="cam-list">
                    <option value="environment" selected>Environment Facing (default)</option>
                    <option value="user">User Facing</option>
                  </select>
                  <IconActionButton action={() => {
                    scanner.toggleFlash();
                    let flashToggle = $('#flash-toggle');
                    if (scanner.isFlashOn()) {
                      flashToggle.addClass('flashOn');
                    } else {
                      flashToggle.removeClass('flashOn');
                    }
                  }} state={true} id="flash-toggle" className={`flash`} icon={<FontAwesomeIcon icon="fa-solid fa-bolt" />}></IconActionButton>
                </div>

                <div id="video-container" className="w-100">
                  <video id="qr-video" className="w-100"></video>
                </div>
              </div>

              {/*{!startScan &&*/}
              {/*    <div className="qrcodesvg" onClick={toggleShowQrReader}>*/}
              {/*      <QRCodeIcon className="chevronBack"/>*/}
              {/*    </div>*/}
              {/*}*/}
              <div className="row">
                {/*{!startScan &&*/}
                {/*    <button id="newButtonScan" className="button offset-lg-4 col-lg-4" onClick={toggleShowQrReader}>{CacheExtension.getDataFromCache("new.buttonScan")}</button>*/}
                {/*}*/}
                <div className={'form-group offset-lg-4 col-lg-4 mt-4 d-flex justify-content-center'}>
                  <p>{CacheExtension.getDataFromCache("new.help")}</p>
                </div>
                {serial && gtin && product &&
                    <div className={'form-group offset-lg-4 col-lg-4 mt-4'}>
                      <div className={"mb-2"}>{CacheExtension.getDataFromCache("productFound")} :</div>
                      <div>{product.model.title}</div>
                      <div>{product.model.subtitle}</div>
                    </div>
                }
                <button disabled={!serial || !gtin} id="newButtonNew" className="button offset-lg-4 col-lg-4" onClick={addNewProduct}>{CacheExtension.getDataFromCache(logged ? "new.buttonNew" : "OwnAndCreate")}</button>
              </div>

            </div>

            <form style={{display: 'none'}} className={'formNew'} onSubmit={(e) => submitSubscribe(e)}>
              <input type="text" placeholder={CacheExtension.getDataFromCache("Firstname")} name="name" id="subscribeFirstname" onChange={handleFormChange}/>
              {errors.name && <div className="showError">{errors.name}</div>}
              <input type="text" placeholder={CacheExtension.getDataFromCache("Lastname")} name="lastname" id="subscribeLastname" onChange={handleFormChange}/>
              {errors.lastname && <div className="showError">{errors.lastname}</div>}
              <input type="text" placeholder={CacheExtension.getDataFromCache("PersonTitle")} name="title" id="title" onChange={handleFormChange}/>
              {errors.title && <div className="showError">{errors.title}</div>}
              <input type="password" placeholder={CacheExtension.getDataFromCache("Password")} name="password" id="subscribePassword" onChange={handleFormChange}/>
              <input type="password" placeholder={CacheExtension.getDataFromCache("ConfirmPassword")} name="confirmation" id="subscribeConfirmation" onChange={(e)=> setConfirmation(e.target.value)}/>
              {errors.password && <div className="showError">{errors.password}</div>}
              <input type="number" placeholder={CacheExtension.getDataFromCache("Age")} name="age" id="age" onChange={handleFormChange} className={'d-none'}/>
              {errors.age && <div className="showError">{errors.age}</div>}
              <input type="email" placeholder={CacheExtension.getDataFromCache("email")} name="email" id="subscribeEmail" onChange={handleChangeEmail}/>
              {errors.mails && <div className="showError">{errors.mails}</div>}
              <input type="tel" placeholder={CacheExtension.getDataFromCache("phone")} name="phone" id="subscribePhone" onChange={handleChangePhone}/>
              {errors.phones && <div className="showError">{errors.phones}</div>}
              {/*<InputComponent onInputChange={(values) => setMails(values)} placeholder={'email'} type={'email'} errors={errors.mails}/>*/}
              {/*<InputComponent onInputChange={(values) => setPhones(values)} placeholder={'phone'} type={'tel'} errors={errors.phones}/>*/}
              {/*<InputComponent onInputChange={(values) => setAddresses(values)} placeholder={'address'} type={'text'} errors={errors.addresses}/>*/}
              <Select
                  isMulti
                  name="optin"
                  className={'d-none'}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral20: 'rgb(255, 158, 23)',
                      neutral30: 'rgb(255, 158, 23)',
                      primary25: 'rgba(255, 158, 23, 0.5)',
                      danger: 'rgb(255, 158, 23)',
                      dangerLight: 'rgba(255, 158, 23, 0.2)',
                      primary: 'black',
                    },
                  })}
                  placeholder={"notifications.select"}
                  options={settings && settings.map((key, i) => ({value: key, label: CacheExtension.getDataFromCache(key)}))}
                  onChange={onChangeSelect}
              />
              <span>{CacheExtension.getDataFromCache("required")}</span>
              <div className="form-check">
                <input style={{width:"auto"}} className="form-check-input" type="checkbox" name="newsletter" id="newsletter"/>
                <label className="form-check-label" htmlFor="newsletter">
                  {CacheExtension.getDataFromCache("newsletter")}
                </label>
              </div>
              <div className="form-check">
                <input
                    style={{width:"auto"}}
                    className="form-check-input"
                    type="checkbox"
                    name="cgv"
                    id="cgv"
                    checked={checkboxes.cgv}
                    onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="cgv">
                  * {CacheExtension.getDataFromCache("cgv")}
                </label>
              </div>
              <div className="form-check">
                <input
                    style={{width:"auto"}}
                    className="form-check-input"
                    type="checkbox"
                    name="rgpd"
                    id="rgpd"
                    checked={checkboxes.rgpd}
                    onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="rgpd">
                  * {CacheExtension.getDataFromCache("rgpd")}
                </label>
              </div>
              <button id="subscribeButton" disabled={!checkboxes.cgv || !checkboxes.rgpd} className="button" type="submit">{CacheExtension.getDataFromCache("OwnAndCreate")}</button>
            </form>
          </m.div>
        </AnimatePresence>
      </>
  );
}


