import {Link, Navigate, useParams} from "react-router-dom";
import React, { useEffect, useState} from "react";
import { ReactComponent as VolIcon } from '../../res/vol.svg';
import { ReactComponent as BackIcon } from '../../res/back.svg';
import '../../styles/lost.css';
import {motion as m} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import CollapseComponent from "../../StarterKit/Components/CollapseComponent";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";

export default function LostPage() {
    let currentDate = new Date().toISOString().split('T')[0];

    const initialForm = {
        lost: { date: currentDate, comment: '' },
        stolen: { date: currentDate, comment: '' },
        destroyed: { date: currentDate, comment: '' },
    };

    const initialCertification = {
        lost: false,
        stolen: false,
        destroyed: false,
    };

    let service = new ProductService()

    let { gtin, serial } = useParams();

    const [data, setData] = useState(null);
    const [certification, setCertification] = useState(initialCertification);
    const [form, setForm] = useState(initialForm);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let result = await service.read(gtin, serial);
            let res = result.getData();
            setData(res);
            setForm(initialForm);
            setCertification(initialCertification);
            setReload(false);  // déplacez cette ligne ici
        };

            fetchData();
    }, [reload]);


    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    const handleFormChange = (event, action) => {
        setForm(prevForm => ({
            ...prevForm,
            [action]: {
                ...prevForm[action],
                [event.target.name]: event.target.value
            }
        }));
    };

    const handleCertificationChange = (action) => {
        setCertification(prevCertification => ({
            ...prevCertification,
            [action]: !prevCertification[action]
        }));
    };

    const handleCancel = async (key) => {
        let res = await service.cancel(key);
        if(res.getCode() === 0 ) {
            setReload(true);
        }
    };

    const handleAction = async (action, key, form) => {
        let res = await service[action](key, form);
        if(res.getCode() === 0 ) {
            setReload(true);
        }
    };

    function collapse(action) {
        return (
            <div className="d-flex flex-column align-items-center">
                <form className={'formNew'}>
                    <p>{CacheExtension.getDataFromCache(action)}</p>
                    <div className="form-group">
                        <input value={form[action].date} className="form-control" type='date' name="date" onChange={(event) => handleFormChange(event, action)} />
                    </div>
                    <input type="text" placeholder={"* " + CacheExtension.getDataFromCache("Comment")} name="comment" onChange={(event) => handleFormChange(event, action)}/>
                    <div className="form-check">
                        <input
                            style={{width:"auto"}}
                            className="form-check-input"
                            type="checkbox"
                            name="certification"
                            id="certification"
                            checked={certification[action]}
                            onChange={() => handleCertificationChange(action)}
                        />
                        <label className="form-check-label" htmlFor="certification">
                            * {CacheExtension.getDataFromCache("certification")}
                        </label>
                    </div>
                </form>
                <button className="w-50 button" disabled={!certification[action]} onClick={() => handleAction(action, data.key, form[action])}>
                    {CacheExtension.getDataFromCache("Confirm")}
                </button>
            </div>
        )
    }
    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                {data &&
                    <>
                        <div className={'titleHead'} id="header">
                            <Link className="backLink" id="lostBack" to={ `/product/${gtin}/${serial}/service` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                            <h1 id="lostTitle">{CacheExtension.getDataFromCache("lost.title")}</h1>
                            <span id="productTokenText">{CacheExtension.getDataFromCache("lost.text")}</span>
                        </div>
                        <div id="content">
                            {data.state === 'lost' || data.state === 'stolen' || data.state === 'destroyed' ?
                                <>
                                    {data.state === 'lost' && <div>{CacheExtension.getDataFromCache("watchIsLost")}</div>}
                                    {data.state === 'stolen' && <div>{CacheExtension.getDataFromCache("watchIsStolen")}</div>}
                                    {data.state === 'destroyed' && <div>{CacheExtension.getDataFromCache("watchIsDestroyed")}</div>}
                                    <button className="btn btnBo mt-3" onClick={() => handleCancel(data.key)}>{CacheExtension.getDataFromCache("CancelDeclaration")}</button>
                                </>
                                :
                                <>
                                    <ul className="container__link__service">
                                        <li>
                                            <div className={'w-100 d-flex'}>
                                                <VolIcon id="productMenuIcon" width="1rem"/>
                                                <CollapseComponent buttonClass={'collapseBtn'}
                                                                   buttonText={CacheExtension.getDataFromCache("lost.lost")}
                                                                   dom={collapse('lost')}/>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={'w-100 d-flex'}>
                                                <VolIcon id="productMenuIcon" width="1rem"/>
                                                <CollapseComponent buttonClass={'collapseBtn'}
                                                                   buttonText={CacheExtension.getDataFromCache("lost.stolen")}
                                                                   dom={collapse('stolen')}/>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={'w-100 d-flex'}>
                                                <VolIcon id="productMenuIcon" width="1rem"/>
                                                <CollapseComponent buttonClass={'collapseBtn'}
                                                                   buttonText={CacheExtension.getDataFromCache("lost.destroyed")}
                                                                   dom={collapse('destroyed')}/>
                                            </div>
                                        </li>
                                    </ul>
                                </>
                            }
                        </div>
                    </>
                }
            </m.div>
            <Footer selected="wallet"/>
        </>
    );
}