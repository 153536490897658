import {Link, useNavigate} from "react-router-dom";
import '../../styles/wallet.css';
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import { ReactComponent as VolIcon } from '../../res/vol.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ProductCard({ data }) {

    const navigate = useNavigate()

    return (
    <>
        <div className={'py-2 col-12 col-lg-3'} onClick={() => navigate(`/product/` + data.gtinSku + '/' + data.serial)}>
            <div className="productCard">
                <div className="productCardTitle p-2">
                    {data.title && <strong>{data.title}</strong>}
                    {data.subtitle && <div style={{fontSize:'10px'}}>{data.subtitle}</div>}
                </div>
                <div className={'productCardContent mb-1'}>
                    <div className={'productCardHeader p-2 d-flex w-100'}>
                        {!(data.state === 'lost' || data.state === 'stolen' || data.state === 'destroyed' || data.state === 'sav') && (
                            <>
                                {data.state === 'owned' && ((!data.invoice || data.invoice == 'reminder') &&
                                    <div className="invoiceMissing"><p>{CacheExtension.getDataFromCache("invoiceMissing")}</p></div>
                                )}
                                {data.state === 'inVerification' && (data.invoice && data.invoice != 'Refused' && data.invoice != 'reminder' &&
                                    <div className="invoiceVerif"><p>{CacheExtension.getDataFromCache("invoiceVerif")}</p></div>
                                )}
                                {data.state === 'inVerification' && ((!data.invoice || data.invoice == 'reminder' || data.invoice == 'Refused') &&
                                    <div className="invoiceVerif"><p>{CacheExtension.getDataFromCache("invoiceActivate")}</p></div>
                                )}
                            </>
                        )}

                        {data.state === 'sav' &&
                            <div className="invoiceMissing"><p>{CacheExtension.getDataFromCache("savCard")}</p></div>
                        }
                        {data.state === 'lost' &&
                            <div className="invoiceMissing"><p>{CacheExtension.getDataFromCache("lostCard")}</p></div>
                        }
                        {data.state === 'stolen' &&
                            <div className="invoiceMissing"><p>{CacheExtension.getDataFromCache("stolenCard")}</p></div>
                        }
                        {data.state === 'destroyed' &&
                            <div className="invoiceMissing"><p>{CacheExtension.getDataFromCache("destroyedCard")}</p></div>
                        }
                    </div>
                    <div className={'productCardPicture'} style={{backgroundImage : `url(${data.image})`,mixBlendMode:'multiply' }}></div>
                </div>
            </div>

        </div>
    </>
    );
}








