// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapseBtn {
    border: none;
    background-color: white;
    width: 100%;
    text-align: start;
}

input[type="date"] {
    border: solid 1px var(--main-hg-color)!important;
    padding: var(--inputPadding);
    border-radius: 0;
}

input[type="date"]:focus {
    border: solid 2px black!important;
    border-radius: 4px;
    box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/lost.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,gDAAgD;IAChD,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".collapseBtn {\n    border: none;\n    background-color: white;\n    width: 100%;\n    text-align: start;\n}\n\ninput[type=\"date\"] {\n    border: solid 1px var(--main-hg-color)!important;\n    padding: var(--inputPadding);\n    border-radius: 0;\n}\n\ninput[type=\"date\"]:focus {\n    border: solid 2px black!important;\n    border-radius: 4px;\n    box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
