// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#subscribeTitle {
	color: var(--main-hg-color);
}

#subscribeIntro {
	margin-bottom: 30px;
}

#subscribeFirstname {
	
}

#subscribeLastname {
	
}

#subscribeEmail {
	
}

#subscribePassword {
	
}

#subscribeButton {
	background-color: var(--main-hg-color);
}

#subscribeLogin {
	margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/subscribe.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;AAC5B;;AAEA;CACC,mBAAmB;AACpB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;CACC,sCAAsC;AACvC;;AAEA;CACC,iBAAiB;EAChB,kBAAkB;AACpB","sourcesContent":["#subscribeTitle {\n\tcolor: var(--main-hg-color);\n}\n\n#subscribeIntro {\n\tmargin-bottom: 30px;\n}\n\n#subscribeFirstname {\n\t\n}\n\n#subscribeLastname {\n\t\n}\n\n#subscribeEmail {\n\t\n}\n\n#subscribePassword {\n\t\n}\n\n#subscribeButton {\n\tbackground-color: var(--main-hg-color);\n}\n\n#subscribeLogin {\n\tmargin-left: auto;\n  margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
