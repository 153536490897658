import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./App/Routes/ErrorPage";
import InformationsPage from "./App/Routes/InformationsPage";
import NewPage from "./App/Routes/NewPage";
import ProductDescriptionPage from "./App/Routes/ProductDescriptionPage";
import ProductHistoryPage from "./App/Routes/ProductHistoryPage";
import ProductPage from "./App/Routes/ProductPage";
import ProductTokenPage from "./App/Routes/ProductTokenPage";
import ProfilePage from "./App/Routes/ProfilePage";
import SubscribePage from "./App/Routes/SubscribePage";
import UpdateCoordinatesPage from "./App/Routes/UpdateCoordinatesPage";
import UpdateProfilePage from "./App/Routes/UpdateProfilePage";
import WalletPage from "./App/Routes/WalletPage";
import StorePage from "./App/Routes/StorePage";
import TutorialPage from "./App/Routes/TutorialPage";
import ProductServicePage from "./App/Routes/ProductServicePage";
import MaintenancePage from './App/Routes/MaintenancePage'
import Logout from "./StarterKit/Route/Logout";
import LoginPage from "./App/Routes/LoginPage";
import LostPage from "./App/Routes/LostPage";
import WarrantyPage from "./App/Routes/WarrantyPage";
import EstimatePage from "./App/Routes/EstimatePage";
import TransferPage from "./App/Routes/TransferPage";
import ProductInvoicePage from "./App/Routes/ProductInvoicePage";
import SupportPage from "./App/Routes/SupportPage";
import NotificationsPage from "./App/Routes/NotificationsPage";
import ActivatePage from "./App/Routes/ActivatePage";
import QrLinkPage from "./App/Routes/QrLinkPage";
import SubscribeAndOwnPage from "./App/Routes/SubscribeAndOwnPage";
import ResendActivationLinkPage from "./App/Routes/ResendActivationLinkPage";
import ResetPage from "./App/Routes/ResetPage";

var root = document.getElementById('root');

window.addEventListener('ref',function () {
  load()
})

const router = createBrowserRouter([
 
  { 
    path: "/login", 
    element: <LoginPage />,
    errorElement: <ErrorPage /> 
  },
  {
    path: "/logout",
    element: <Logout />,
    errorElement: <ErrorPage />
  },
  { 
    path: "/subscribe", 
    element: <SubscribePage />, 
    errorElement: <ErrorPage /> 
  },
  {
    path: "/subscribe/:gtin/:serial",
    element: <SubscribeAndOwnPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/activate/:client",
    element: <ActivatePage />,
    errorElement: <ErrorPage />
  },
  // {
  //   exact: true,
  //   path: "/01/:gtin/10/:year/20/:sku/21/:serial/91/:svid/:signature",
  //   element: <QrLinkPage />,
  //   errorElement: <ErrorPage />
  // },
  {
    path: "/add-watch",
    element: <QrLinkPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/resend",
    element: <ResendActivationLinkPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/reset/:user/:token",
    element: <ResetPage />,
    errorElement: <ErrorPage />
  },
  { 
    path: "/store", 
    element: <StorePage />, 
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/profile", 
    element: <ProfilePage />, 
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/profile/update", 
    element: <UpdateProfilePage />, 
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/profile/coordinates", 
    element: <UpdateCoordinatesPage />, 
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/infos", 
    element: <InformationsPage />, 
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/product/:gtin/:serial",
    element: <ProductPage  />,
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/product/:gtin/:serial/description",
    element: <ProductDescriptionPage />, 
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/product/:gtin/:serial/history",
    element: <ProductHistoryPage />, 
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/product/:gtin/:serial/token",
    element: <ProductTokenPage />,
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/",
    element: <WalletPage />, 
    errorElement: <ErrorPage />
  },
  { 
    path: "/tutorial", 
    element: <TutorialPage />, 
    errorElement: <ErrorPage />
  },
  {
    path: "/product/:gtin/:serial/invoice",
    element: <ProductInvoicePage />,
    errorElement: <ErrorPage />
  },
  { 
    path: "/product/:gtin/:serial/service",
    element: <ProductServicePage />,
    errorElement: <ErrorPage /> 
  },
  { 
    path: "/profile/notifications",
    element: <NotificationsPage />,
    errorElement: <ErrorPage /> 
  },
  {
    path: "/new",
    element: <NewPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/product/:gtin/:serial/service/maintenance",
    element: <MaintenancePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/product/:gtin/:serial/service/support",
    element: <SupportPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/product/:gtin/:serial/service/warranty",
    element: <WarrantyPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/product/:gtin/:serial/service/estimate",
    element: <EstimatePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/product/:gtin/:serial/service/lost",
    element: <LostPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/product/:gtin/:serial/service/transfer",
    element: <TransferPage />,
    errorElement: <ErrorPage />
  }

]);



function load() {
  var header = document.getElementById('header')
  var header__product = document.querySelector('.header__product')


  // if (header) {
  //   var offfset = header.offsetHeight;
  //   if (header.classList.contains('titleHead')) {
  //     root.style.paddingTop = offfset + 'px'
  //
  //   } else {
  //     root.style.paddingTop = 0 + 'px'
  //   }
  // }

  var footer = document.getElementById('footer')
  if (footer) {
    root.classList.add('footerHeight')
    root.style.paddingBottom = footer.offsetHeight + 'px'
  } else {
    root.classList.remove('footerHeight')
    root.style.paddingBottom = 0 + 'px'
  }

  if (footer) {
    var elementfooter = document.querySelectorAll('.footerItemContainer div')

    for (let i = 0; i < elementfooter.length ; i++) {
      elementfooter[i].addEventListener('click',function () {
        
      })
    }
  }
}
export function removeHashFromUrl() {
  if (window.location.href.includes('#')) {
    const url = window.location.href.replace('/#', '/');
    window.location.href = url;
  }
  return null;
}

window.addEventListener('load',function () {
  setTimeout(() => {
    load();
    detectBacklink();
    fulllink();
    addclassroot();

  }, "200")
})


window.addEventListener('click',function () {
  var footer = document.getElementById('footer')
  var header__product = document.querySelector('.header__product')
  setTimeout(() => {
    if (!header__product) {
      load();
    }
    detectBacklink();
    fulllink();
    addclassroot();
  }, "300")
  if (header__product) {
    setTimeout(() => {
        load();
    }, "50")
  }
})

function detectBacklink() {
  var productTitleBack = document.getElementById('productTitleBack')
  var Wallet = document.querySelector('.walletPage')
  if (productTitleBack) {
    productTitleBack.addEventListener('click',function () {
      localStorage['backclick'] = 1;
    })
  } else if (Wallet) {
  } else {
    localStorage.removeItem('backclick')
  }
}


function fulllink() {
  setTimeout(() => {
    var fullLink = document.querySelectorAll('a')
    for (let i = 0; i < fullLink.length; i++) {
      fullLink[i].addEventListener('click',function () {
        var root = document.getElementById('root')
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      })
    }
  }, "200")
}

function addclassroot() {
  var header__product = document.querySelector('.header__product')
  if (header__product) {
    var addclassRoot = document.getElementById('root')
    addclassRoot.classList.add('productHeader')
  } else {
    var addclassRoot = document.getElementById('root')
    addclassRoot.classList.remove('productHeader')
  }
}













export default router;
